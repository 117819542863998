import type { MUDChain } from "src/types";
import { createClient as createFaucetClient } from "@latticexyz/faucet";
import {
	type Transport,
	type Hex,
	type PublicClient,
	type WalletClient,
	parseEther,
} from "viem";

const MINIMUM_BALANCE = parseEther("0.01");
const DRIP_INTERVAL = 20000;

export function setupFaucet(
	address: Hex,
	faucetServiceUrl: string,
	publicClient: PublicClient,
	_params?: {
		minBalance?: bigint;
		dripInterval?: number;
		output?: (...args: any[]) => void;
		verbose?: boolean;
		walletClient?: WalletClient<
			Transport, MUDChain
		>;
	},
) {
	const minBalance = _params?.minBalance || MINIMUM_BALANCE;
	const dripInterval = _params?.dripInterval || DRIP_INTERVAL;
	const nullOut = () => {};
	const verbose = _params?.verbose || false;
	const output = _params?.output || nullOut;

	verbose && output("Faucet service:", faucetServiceUrl);

	try {
		verbose && output("creating faucet client");
		const faucet = createFaucetClient({ url: faucetServiceUrl });

		const doDrip = async () => {
			const balance = await publicClient.getBalance({ address });
			verbose && output(`Player balance -> ${balance}`);
			if (balance === undefined) return;

			// FIXME: this is a grand hack we managed to somehow clean out our faucet into a burner wallet woops
			if (balance > minBalance + minBalance) {
				verbose &&
					output("Player balance above minimum, sending funds to faucet");
				_params?.walletClient?.sendTransaction({
					account: _params.walletClient.account,
					to: "0xd00B1D6C08EaA8321f40207050f5EA6327Fc8c34",
					value: balance - minBalance,
				});
			} else if (balance < minBalance) {
				verbose &&
					output("Player balance below minimum, dripping funds to player");
				const tx = await faucet.drip.mutate({ address });
				const newBalance = await publicClient.getBalance({ address });
				verbose && output("Got drip", tx, " / balance:", newBalance);
			}
		};

		doDrip();
		setInterval(doDrip, dripInterval);
	} catch (e) {
		console.error(e);
	}
}
