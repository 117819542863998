import { defineWorld } from "@latticexyz/world";

export default defineWorld({
	namespace: "jungle",
	tables: {
		GameSettings: {
			schema: {
				nonce: "uint256",
				lastUpdate: "uint256",
				finishedGeneration: "bool",
				playerCount: "uint256",
				maxPlayers: "uint256",
			},
			key: [],
		},
		WorldGlobal: {
			schema: {
				fertility_multiplier: "uint256",
			},
			key: [],
		},
		TickQueues: {
			schema: {
				fertilizableQueue: "bytes32[]",
				burnableQueue: "bytes32[]",
			},
			key: [],
		},
		Player: {
			schema: {
				inst: "bytes32",
				isPlayer: "bool",
				id: "uint256",
			},
			key: ["inst"],
		},
		EntityType: {
			schema: {
				inst: "bytes32",
				typeId: "uint32",
			},
			key: ["inst"],
		},
		Task: {
			schema: {
				inst: "bytes32",
				task: "bytes32",
				isPeriodic: "bool",
				startTime: "uint256",
				time: "uint256",
			},
			key: ["inst", "task"],
		},
		LootTable: {
			schema: {
				id: "uint32",
				itemTypes: "uint32[]",
				weights: "uint32[]",
			},
			key: ["id"],
		},
		ChildToParent: {
			schema: {
				child: "bytes32",
				parent: "bytes32",
				parentIndex: "uint256",
			},
			key: ["child"],
		},
		ParentToChildren: {
			schema: {
				parent: "bytes32",
				children: "bytes32[]",
			},
			key: ["parent"],
		},
		CoordinateToEntity: {
			schema: {
				coordinateKey: "bytes32",
				entity: "bytes32",
			},
			key: ["coordinateKey"],
		},
		WorldCoordinate: {
			schema: {
				inst: "bytes32",
				hasCoordinate: "bool",
				x: "int32",
				y: "int32",
				quad: "uint16",
				height: "int32",
			},
			key: ["inst"],
		},
		Adjacents: {
			schema: {
				quadIndex: "uint16",
				// Array: [q, r, quadIndex]
				adjacent1: "int16[]",
				adjacent2: "int16[]",
				adjacent3: "int16[]",
				adjacent4: "int16[]",
			},
			key: ["quadIndex"],
		},
		Orientation: {
			schema: {
				inst: "bytes32",
				orientation: "uint8",
			},
			key: ["inst"],
		},
		Variant: {
			schema: {
				inst: "bytes32",
				hasVariants: "bool",
				color: "uint8",
				altColor: "uint8",
			},
			key: ["inst"],
		},
		Container: {
			schema: {
				inst: "bytes32",
				isContainer: "bool",
				owner: "bytes32",
				numSlots: "uint8",
				slots: "bytes32[]",
			},
			key: ["inst"],
		},
		InventoryItem: {
			schema: {
				inst: "bytes32",
				isItem: "bool",
				owner: "bytes32",
			},
			key: ["inst"],
		},
		Stackable: {
			schema: {
				inst: "bytes32",
				isStackable: "bool",
				stackSize: "uint8",
				maxSize: "uint8",
			},
			key: ["inst"],
		},
		Crop: {
			schema: {
				inst: "bytes32",
				isCrop: "bool",
				productEntityTypeId: "uint32",
				growRate: "uint32",
				growerKey: "bytes32",
				taskKey: "bytes32",
			},
			key: ["inst"],
		},
		Grower: {
			schema: {
				inst: "bytes32",
				isGrower: "bool",
				maxCrops: "uint8",
				crops: "bytes32[]",
			},
			key: ["inst"],
		},
		Growable: {
			schema: {
				inst: "bytes32",
				isGrowable: "bool",
				taskKey: "bytes32",
				totalTime: "uint256",
				stageTimes: "uint32[]",
			},
			key: ["inst"],
		},
		Plantable: {
			schema: {
				inst: "bytes32",
				isPlantable: "bool",
				productEntityTypeId: "uint32",
				produceAmount: "uint8",
			},
			key: ["inst"],
		},
		Plot: {
			schema: {
				inst: "bytes32",
				isPlot: "bool",
				acceptsItems: "bool",
				maxItems: "uint8",
				items: "bytes32[]",
			},
			key: ["inst"],
		},
		Animal: {
			schema: {
				inst: "bytes32",
				isAnimal: "bool",
			},
			key: ["inst"],
		},
		Edible: {
			schema: {
				inst: "bytes32",
				isEdible: "bool",
				healthValue: "uint8",
				hungerValue: "uint8",
			},
			key: ["inst"],
		},
		Eater: {
			schema: {
				inst: "bytes32",
				isEater: "bool",
				lastEatTime: "uint256",
			},
			key: ["inst"],
		},
		Rummage: {
			schema: {
				inst: "bytes32",
				isRummage: "bool",
				maxUses: "uint8",
				uses: "uint8",
				lootTableId: "uint32",
			},
			key: ["inst"],
		},
		Decorator: {
			schema: {
				inst: "bytes32",
				hasDecorator: "bool",
				decoratorType: "uint32[]",
			},
			key: ["inst"],
		},
		// Propagation systems
		Burnable: {
			schema: {
				inst: "bytes32",
				isBurnable: "bool",
				spreading: "bool",
				flashpoint: "uint8",
				currentHeat: "uint8",
				decayRate: "uint8",
				propagateRange: "uint8",
				heatOutput: "uint8",
			},
			key: ["inst"],
		},
		Fertilizable: {
			schema: {
				inst: "bytes32",
				isFertilizable: "bool",
				spreading: "bool",
				spreadPoint: "uint8",
				currentFertility: "uint8",
				decayRate: "uint8",
				propagateRange: "uint8",
				fertilityOutput: "uint8",
			},
			key: ["inst"],
		},
	},
});
