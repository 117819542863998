import { CBuildable } from "@game/sim/components/CBuildable";
import { CCrop } from "@game/sim/components/CCrop";
import { CInventoryItem } from "@game/sim/components/CInventoryItem";
import { CPlantable } from "@game/sim/components/CPlantable";
import { CRenderer } from "@game/sim/components/CRenderer";
import { CStackable } from "@game/sim/components/CStackable";
import { CUpdate } from "@game/sim/components/CUpdate";
import type { SimEntity } from "@game/sim/SimEntity";
import { SimEntityPrefab } from "@game/sim/SimEntityPrefab";
import { ItemBambooRenderer } from "./bamboo.renderer";
import { registerEntity } from "@/data/entity/entity.data.fn";
import type { TEntityDefinition } from "@/data/entity/entity.data.types";
import { CTransmute } from "@game/sim/components/CTransmute";

export class ItemBambooPrefab extends SimEntityPrefab {
	crop: CCrop | null = null;

	constructor(e: SimEntity) {
		super(e);
		e.addComponent(CInventoryItem).setSigil("bamboo");
		e.addComponent(CRenderer).setRenderer(ItemBambooRenderer);
		e.addComponent(CStackable).setMaxSize(30);
		e.addComponent(CPlantable).setProductPrefab(ItemBambooPrefab);
		e.addComponent(CBuildable);
		this.crop = e.addComponent(CCrop);
		e.addComponent(CUpdate).setUpdateFn(this.update.bind(this));
		e.addComponent(CTransmute);
	}

	update = () => {
		this.crop?.checkGrowth();
	};
}

const entityData: TEntityDefinition = {
	name: "Bamboo",
	simEntityPrefab: ItemBambooPrefab,
	id: 30001,
	metadata: {
		icon: "bamboo.webp",
	},
};

registerEntity(entityData);
