import { Debug } from "@/lib/debug/debug";

export type TFormula = {
  name: string;
  activeItem: number[];
  combinesWith: number[];
  produces: number;
  destroysActiveItem: boolean;
}

const DATA_FORMULAS: Record<string, TFormula> = {};

export const getFormula = () => ({ ...DATA_FORMULAS });

export const getFormulaByItems = (activeItem: number, targetItem: number): TFormula | undefined => {
	return Object.values(DATA_FORMULAS).find((f) => f.activeItem.includes(activeItem) && f.combinesWith.includes(targetItem));
}

export const getFormulaByNames = (...names: string[]): TFormula[] => {
	return names.map((name) => {
		if (!DATA_FORMULAS[name]) {
			throw new Error(`No formula found for name ${name}`);
		}
		return DATA_FORMULAS[name];
	});
};

export const registerFormula = (formula: TFormula) => {
	if (DATA_FORMULAS[formula.name]) {
		Debug("Formula").warn(
			`Registering duplicate formula ${formula.name}`,
		);
	}
	DATA_FORMULAS[formula.name] = formula;
};

