import { registerEntity } from "@/data/entity/entity.data.fn";
import type { TEntityDefinition } from "@/data/entity/entity.data.types";
import { CBuildable } from "@game/sim/components/CBuildable";
import type { CCrop } from "@game/sim/components/CCrop";
import { CInventoryItem } from "@game/sim/components/CInventoryItem";
import { CPlantable } from "@game/sim/components/CPlantable";
import { CStackable } from "@game/sim/components/CStackable";
import type { SimEntity } from "@game/sim/SimEntity";
import { SimEntityPrefab } from "@game/sim/SimEntityPrefab";

export class ItemMixedSeedPrefab extends SimEntityPrefab {
	crop: CCrop | null = null;

	constructor(e: SimEntity) {
		super(e);
		e.addComponent(CInventoryItem).setSigil("seeds");
		e.addComponent(CStackable).setMaxSize(30);
		e.addComponent(CPlantable).setProductPrefab(ItemMixedSeedPrefab);
		e.addComponent(CBuildable);
	}
}

const entityData: TEntityDefinition = {
	name: "Mystery Seed",
	simEntityPrefab: ItemMixedSeedPrefab,
	id: 31001,
	metadata: {
		icon: "mixedseed.webp",
	},
};

registerEntity(entityData);
