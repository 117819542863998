import { getMesh, getTexture } from "@/data/assetLoader/asset.store";
import { getColors } from "@/data/colors/palette.data";
import { randomFromArray, seededRand } from "@/lib/random/seeded.functions";
import { Interactable } from "@game/input/interactable";
import useEntityUpdate from "@game/sim/useEntityUpdate";
import { Block } from "@game/world/block";
import { Sparkles } from "@react-three/drei";
import { useCallback, useMemo, useRef, useState } from "react";
import {
  DoubleSide,
  type Material,
  type Mesh,
  MeshStandardMaterial,
  SplineCurve,
  Vector2,
} from "three";
import { CRenderer, type TRendererProps } from "../sim/components/CRenderer";
import { CConstruction } from "@game/sim/components/CConstruction";
import { getMUDState, tables } from "@mud/index";
import type { Hex } from "viem";
import { UIStore } from "@game/ui/ui.store";

const growthCurve = new SplineCurve([
  new Vector2(0, 0.15),
  new Vector2(0.5, 0.19),
  new Vector2(0.501, 0.5),
  new Vector2(0.75, 0.55),
  new Vector2(0.751, 0.95),
  new Vector2(1, 1),
]);

export function JungleRenderer({ entity }: TRendererProps) {
  const [isInteractable, setIsInteractable] = useState(false);
  const [isForageable, setIsForageable] = useState(false);
  const meshRef1 = useRef<Mesh>(null!);
  const scale = useRef<number[]>([0, 0, 0]);
  const [growthProgress, setGrowthProgress] = useState(0);

  const { block, pos } = useMemo(() => {
    const b = entity.component(CRenderer).block;
    return { block: b, pos: b?.position };
  }, [entity]);

  const { mat, mesh, rot } = useMemo(() => {
    if (!block) return {};
    const rand = seededRand(block._seed);
    const plantsMesh = getMesh("jungleplants/jungleplants/jungle000");
    if (!plantsMesh) return {};

    const color = randomFromArray(getColors("jungle"), rand).hex;
    const alphaMap = getTexture("jungleplants/plantas_dif_007_alpha");
    const material = plantsMesh.material as Material;
    const map = (material as MeshStandardMaterial).map;
    const mat = new MeshStandardMaterial({
      map: map,
      metalnessMap: map,
      emissiveMap: map,
      alphaMap: alphaMap,
      color: color,
      transparent: true,
      opacity: 1,
      depthWrite: true,
      fog: true,
      side: DoubleSide,
    });

    const mesh = plantsMesh.geometry;
    const rot = [
      (rand(0, 1000) / 1000) * 1 - 0.5,
      (rand(0, 1000) / 1000) * 360,
      (rand(0, 1000) / 1000) * 1 - 0.5,
    ] as [number, number, number];
    return { mat, mesh, color, rot };
  }, [block]);

  const calculateScale = useCallback(() => {
    if (!block) return;
    const fertilize = getMUDState().getValue(tables.Fertilizable, {
      inst: entity.ref as Hex,
    });
    if (!parent) {
      console.error("no parent", entity.ref);
    }
    const percent = Math.max(
      0.01,
      Math.min(1, (fertilize?.currentFertility || 0.01) / 100)
    );
    if (UIStore().hoveredObject === entity.ref) {
      console.log(percent);
    }
    if (growthProgress === percent) return;
    const rand = seededRand(Block.getSeed(block));
    const maxScale = rand(1.525, 1.55) * 0.09;
    setGrowthProgress(percent);
    const t = Math.max(0.01, percent);
    const s = (growthCurve.getPointAt(t)?.y || 0) * maxScale || 0;
    scale.current = [s, s, s];
    meshRef1.current?.scale.set(s, s, s);
    setIsForageable(percent >= 0.3);
    if (percent >= 0.3) {
      setIsInteractable(true);
      entity
        .parent()
        ?.component(CConstruction)
        ?.setBuildDirections({ TOP: false });
    } else {
      if (isInteractable) {
        setIsInteractable(false);
        entity
          .parent()
          ?.component(CConstruction)
          ?.setBuildDirections({ TOP: true });
      }
    }
  }, [entity, isInteractable, block]);

  useEntityUpdate(calculateScale, entity);

  const renderer = useMemo(() => {
    return (
      <group position={pos}>
        <Interactable
          entity={entity}
          position={[0, 0, 0]}
          args={[0.2, 32, 32]}
          collider="Sphere"
          isInteractable={isInteractable}
          debug={false}
        />
        <mesh
          position={[0, -0.265, 0]}
          rotation={rot}
          ref={meshRef1}
          geometry={mesh || undefined}
          material={mat}
          castShadow
          receiveShadow
        />
        {growthProgress > 0.5 && (
          <Sparkles
            size={3.125}
            speed={0.2}
            count={5}
            noise={50}
            color={"#69CD1E"}
            scale={0.25}
            position={[0, 0, 0]}
          />
        )}
      </group>
    );
  }, [entity, pos, mat, mesh, isInteractable, rot, isForageable, growthProgress]);

  return <>{renderer}</>;
}
