import { CAnimal } from "../components/CAnimal";
import { CConstruction } from "../components/CConstruction";
import { CContainer } from "../components/CContainer";
import { CCrop } from "../components/CCrop";
import { CEater } from "../components/CEater";
import { CEdible } from "../components/CEdible";
import { CGrower } from "../components/CGrower";
import { CInspectable } from "../components/CInspectable";
import { CInventoryItem } from "../components/CInventoryItem";
import { CPaint } from "../components/CPaint";
import { CPlayer } from "../components/CPlayer";
import { CPlot } from "../components/CPlot";
import { CRummage } from "../components/CRummage";
import { CTransmute } from "../components/CTransmute";
import { CUsable } from "../components/CUsable";
import type { IAction } from "./actions.types";
import type { BufferedAction } from "./bufferedAction";

export type ActionsMap = {
	[K in keyof typeof _ACTIONS]: IAction;
};

export const _ACTIONS = {
	ADD_BUILD: {
		fn: async (act: BufferedAction): Promise<boolean> => {
			if (act.target?.component(CConstruction)) {
				const recipe = act.target
					?.component(CConstruction)
					.getRecipeByIngredient(act.invObject!);
				if (recipe && act.invObject) {
					await act.target
						.component(CConstruction)
						.buildRecipe(act.doer, recipe, act.invObject);
					return true;
				}
			}
			return false;
		},
		strFn: (act: BufferedAction) => {
			if (act.target?.component(CConstruction)) {
				const recipe = act.target
					?.component(CConstruction)
					.getRecipeByIngredient(act.invObject!);
				if (recipe?.strFn) return recipe.strFn(act);
				if (recipe) return `Build ${recipe.displayName || recipe.name}`;
			}
			return "ERROR: No recipe";
		},
		buildAction: true,
	},
	COMBINESTACK: {
		fn: async (_act: BufferedAction): Promise<boolean> => {
			console.warn("TODO: COMBINESTACK action");
			return false;
		},
		strFn: (act: BufferedAction) => {
			return `Combine ${act.target?.getName()}`;
		},
	},
	DROP_ITEM: {
		fn: async (act: BufferedAction): Promise<boolean> => {
			if (act.target?.component(CInventoryItem)) {
				await act.doer.component(CPlayer).dropItem(act.doer, act.target);
			}
			return true;
		},
		strFn: (act: BufferedAction) => {
			return `Drop ${act.target?.getName()}`;
		},
	},
	FEED: {
		fn: async (act: BufferedAction): Promise<boolean> => {
			if (act.target?.component(CEater) && act.invObject?.component(CEdible)) {
				await act.target?.component(CEater).feed(act.doer, act.invObject);
				return true;
			}
			return false;
		},
		strFn: (act: BufferedAction) => {
			return `Feed ${act.target?.getName()}`;
		},
	},
	FOCUS_ISLAND: {
		fn: async (_act: BufferedAction): Promise<boolean> => {
			return true;
		},
		strFn: (act: BufferedAction) => {
			return `Focus ${act.target?.getName()}`;
		},
	},
	HARVEST: {
		fn: async (act: BufferedAction): Promise<boolean> => {
			console.log(act.target);
			if (
				act.target?.component(CCrop)?.isReadyForHarvest() &&
				!act.target.component(CInventoryItem)?.isHeld()
			) {
				await act.target.component(CCrop).harvest(act.doer);
				return true;
			}
			return false;
		},
		strFn: (act: BufferedAction) => {
			return `Harvest ${act?.target?.getName() || "ERROR"}`;
		},
	},
	LOOKAT: {
		fn: async (_act: BufferedAction): Promise<boolean> => {
			if (_act.target?.component(CInspectable)) {
				console.log(
					_act.target.component(CInspectable).getDescription(_act.target),
				);
				return true;
			}
			return false;
		},
		strFn: (act: BufferedAction) => {
			return `Inspect ${act.target?.getName()}`;
		},
	},
	PET: {
		fn: async (act: BufferedAction): Promise<boolean> => {
			if (act.target?.component(CAnimal)) {
				act.target?.component(CAnimal).pet(act.doer);
				return true;
			}
			return false;
		},
		strFn: (act: BufferedAction) => {
			return `Pet ${act.target?.getName()}`;
		},
	},
	PAINT: {
		fn: async (act: BufferedAction): Promise<boolean> => {
			if (act.target?.hasTag("isPaintable")) {
				await act.invObject?.component(CPaint).paintTarget(act.target);
				return true;
			}
			return false;
		},
		strFn: (act: BufferedAction) => {
			return `Paint ${act.target?.getName()}`;
		},
	},
	PICKUP: {
		fn: async (act: BufferedAction): Promise<boolean> => {
			if (
				act.target?.component(CInventoryItem).owner?.component(CPlot) &&
				act.doer.component(CContainer)
			) {
				const plot = act.target.component(CInventoryItem).owner;
				await plot?.component(CPlot).removeItem(act.doer, act.target);
				return true;
			}
			if (
				act.target?.component(CInventoryItem).canBePickedUp() &&
				act.doer.component(CContainer)
			) {
				await act.doer.component(CContainer).takeItem(act.target);
				return true;
			}
			return false;
		},
		strFn: (act: BufferedAction) => {
			return `Pickup ${act?.target?.getName() || "ERROR"}`;
		},
	},
	PLANT: {
		fn: async (act: BufferedAction): Promise<boolean> => {
			if (act.doer.component(CContainer)) {
				const seed = act.invObject;
				if (seed) {
					return act.target?.component(CGrower).plantItem(seed) || false;
				}
				if (act.invObject) {
					await act.doer.component(CContainer).takeItem(act.invObject);
				}
			}
			return false;
		},
		strFn: (act: BufferedAction) => {
			const seed = act.invObject;
			if (seed) {
				if (act.target?.component(CGrower)) {
					return `Plant ${seed.getName()}`;
				}
			}
			return "";
		},
	},
	PUT: {
		fn: async (act: BufferedAction): Promise<boolean> => {
			if (
				act.target?.component(CPlot) &&
				!act.target?.component(CPlot).isFull() &&
				act.invObject?.component(CAnimal)
			) {
				await act.target?.component(CPlot).putItem(act.doer, act.invObject);
				return true;
			}
			return false;
		},
		strFn: (act: BufferedAction) => {
			return `Put ${act.invObject?.getName()}`;
		},
		buildAction: true,
	},
	RUMMAGE: {
		fn: async (act: BufferedAction): Promise<boolean> => {
			if (act.target?.component(CRummage) && act.doer?.component(CContainer)) {
				if (!act.target) return false;
				await act.target?.component(CRummage).rummage(act.doer);
				return true;
			}
			return false;
		},
		strFn: (act: BufferedAction) => {
			return `Forage ${act.target?.getName()}`;
		},
	},
	TRANSMUTE: {
		fn: async (act: BufferedAction): Promise<boolean> => {
			if (act.invObject?.component(CTransmute) && act.target) {
				await act.invObject
					?.component(CTransmute)
					.transmute(act.doer, act.target);
				return true;
			}
			return false;
		},
		strFn: (act: BufferedAction) => {
			return `Use ${act.invObject?.getName()}`;
		},
	},
	USE_ITEM: {
		fn: async (act: BufferedAction): Promise<boolean> => {
			if (act.invObject?.component(CUsable)) {
				await act.invObject.component(CUsable).useFn?.(act.doer, act.target!);
				return true;
			}
			return true;
		},
		strFn: (act: BufferedAction) => {
			return act.invObject?.component(CUsable).useStrFn?.(act) || "";
		},
	},
} as const;
