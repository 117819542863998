import { logVerbose } from "@/data/dev.store";
import { Debug } from "@/lib/debug/debug";
import { getNetwork, tables, useMUDStore } from "@mud";
import { useEffect } from "react";
import { createEntity } from "./sim/entity.functions";
import { createTaskInstance } from "./sim/sim.functions";
import {
	entityRefs,
	getEntityByRef,
	getTaskByRef,
	removeTask,
	WorldGlobals,
	taskRefs,
	theSim,
	type IWorldGlobals,
} from "./sim/sim.store";
import { useUIStore } from "./ui/ui.store";
import { WorldStore } from "./world/world.store";
import "@game/prefabs/prefabs";
import { loadParticles } from "@/data/particle.data";
import { loadItemShopItems } from "@game/ui/components/itemShop/itemShop.store";
import { logEntities } from "@/data/entity/entity.data.fn";

logEntities();
loadItemShopItems();

export function GameStateUpdate() {
	const entityRecords = useMUDStore((state) =>
		Object.values(state.getRecords(tables.EntityType)),
	);

	const taskRecords = useMUDStore((state) =>
		Object.values(state.getRecords(tables.Task)),
	);

	const gameSettings = useMUDStore((state) =>
		state.getValue(tables.GameSettings, {}),
	);

	const worldGlobal = useMUDStore((state) =>
		state.getValue(tables.WorldGlobal, {}),
	);

	const loadingComplete = useUIStore((state) => state.loadingComplete);

	useEffect(() => {
		const latestBlockSub = getNetwork().latestBlock$.subscribe((block) => {
			if (block?.number !== undefined && Number(block.number) > 0) {
				theSim.latestBlock = Number(block.number);
				theSim.latestTimestamp = Number(block.timestamp);
				theSim.emit("blockUpdate");
			}
		});
		return () => {
			latestBlockSub.unsubscribe();
		};
	}, []);

	// @dev Setup for Tasks
	useEffect(() => {
		if (!loadingComplete) return;
		if (taskRecords.length === 0) return;
		taskRecords.forEach((e) => {
			const task =
				getTaskByRef(e.key.task) ||
				createTaskInstance({ inst: e.key.inst, ref: e.key.task, ...e.value });
			task.startTime = e.value.startTime;
			task.time = e.value.time;
		});
		const refs = taskRefs.keys();
		for (const ref of refs) {
			if (taskRecords.find((f) => f.key.task === ref) === undefined) {
				removeTask(ref);
			}
		}
	}, [taskRecords, loadingComplete]);

	// @dev Setup for Entities
	useEffect(() => {
		if (!loadingComplete) return;
		logVerbose() && console.time("Entity Sync");
		if (entityRecords.length === 0) return;
		const filtered = entityRecords.map((e) => {
			const entity =
				getEntityByRef(e.key.inst) || createEntity(e.key.inst, e.value.typeId);
			return entity;
		});
		const refs = entityRefs.keys();
		for (const ref of refs) {
			if (entityRecords.find((f) => f.key.inst === ref) === undefined) {
				getEntityByRef(ref)?.remove();
			}
		}
		let updateCalls = 0;
		filtered.forEach((e) => {
			e.loadFromChain();
			e.pushEvent("onUpdate", {});
			updateCalls++;
		});
		logVerbose() && Debug("Game").log(`LoadFromChain calls: ${updateCalls}`);
		WorldStore().set({ entities: filtered });
		logVerbose() && console.timeEnd("Entity Sync");
	}, [entityRecords, loadingComplete]);

	useEffect(() => {
		if (!loadingComplete) return;
		loadParticles();
	}, [loadingComplete]);

	useEffect(() => {
		if (!loadingComplete) return;
		if (gameSettings) {
			logVerbose() && console.log(gameSettings);
		}
	}, [gameSettings, loadingComplete]);

	useEffect(() => {
		if (!loadingComplete) return;
		if (worldGlobal) {
			WorldGlobals().set({
				...(worldGlobal as unknown as IWorldGlobals),
			});
		}
	}, [worldGlobal, loadingComplete]);

	return null;
}
