import { getParticleData } from "@/data/particle.data";
import { createParticleEffect } from "@game/effects/particle.functions";
import { Vector3 } from "three";
import { JungleRenderer } from "./jungle.renderer";
import { CGrowable } from "@game/sim/components/CGrowable";
import { CRenderer } from "@game/sim/components/CRenderer";
import { CRummage } from "@game/sim/components/CRummage";
import { CUpdate } from "@game/sim/components/CUpdate";
import type { SimEntity } from "@game/sim/SimEntity";
import { SimEntityPrefab } from "@game/sim/SimEntityPrefab";
import { registerEntity } from "@/data/entity/entity.data.fn";
import type { TEntityDefinition } from "@/data/entity/entity.data.types";
import { registerBlueprint, type TBluePrint } from "@/data/blueprint/blueprint.data";
import Audio from "@game/audio/audioPlayer";
import { UIStore } from "@game/ui/ui.store";
import { getSystemCalls } from "@mud/index";
import { getBuildPosition } from "@game/sim/components/CConstruction.functions";
import type { Hex } from "viem";
import type { BufferedAction } from "@game/sim/actions/bufferedAction";

export class JunglePrefab extends SimEntityPrefab {
	growable: CGrowable | null = null;

	constructor(e: SimEntity) {
		super(e);
		e.addComponent(CRenderer).setRenderer(JungleRenderer);
		const growable = e.addComponent(CGrowable);
		this.growable = growable;
		const stages = [
			{ name: "empty", timing: 10, growFn: growable.checkGrowth },
			{ name: "grassy", timing: 20, growFn: growable.checkGrowth },
			{ name: "fresh jungle", timing: 30, growFn: growable.checkGrowth },
			{ name: "harvestable", timing: 40, growFn: growable.checkGrowth },
			{ name: "wild jungle", timing: 50, growFn: growable.checkGrowth },
		];
		growable.setStages(stages);
		e.addComponent(CRummage);
		e.addComponent(CUpdate).setUpdateFn(this.update.bind(this));
		this.inst.nameFn = this.getName.bind(this);
		this.inst.listenForEvent("rummageStart", this.onRummage.bind(this));
	}

	update = () => {
		this.growable?.checkGrowth();
	};

	getName = () => {
		const growthPercent = this.inst.component(CGrowable).growthPercent;
		if (growthPercent < 0.501) return "Grass";
		if (growthPercent < 0.751) return "Jungle";
		return "Wilderness";
	};

	onRummage = async () => {
		const particle = getParticleData("leaves");
		const position = new Vector3();
		position
			.copy(this.inst.component(CRenderer).block!.position!)
			.add(new Vector3(0, 0, 0));
		await createParticleEffect({
			particle,
			position,
			scale: new Vector3(0.25, 0.25, 0.25),
			lifetime: 3000,
		});
	};
}

const entityData: TEntityDefinition = {
	name: "Jungle",
	simEntityPrefab: JunglePrefab,
	id: 3,
};

const blueprint = {
	name: "Jungle",
	displayName: "Jungle",
	tools: [],
	ingredients: [{ amount: 1, name: "Mystery Seed" }],
	buildingSide: "TOP",
	requiresNeighbour: ["BOTTOM"],
	strFn: (_act: BufferedAction) => {
		return "Plant";
	},
	execute: async (
		doer: SimEntity,
		_recipe: TBluePrint,
		item: SimEntity,
		origin: SimEntity,
	) => {
		const { action_build } = getSystemCalls();
		origin.remove();
		Audio.playSound("PUT");
		const faceIndex = UIStore().faceIndex;
		const block = origin.component(CRenderer).block;
		if (faceIndex === -1) throw new Error("faceIndex not set");
		if (!block) throw new Error("no block");
		const { coordinate, angle } = getBuildPosition(block, faceIndex);
		await action_build(
			doer.ref as Hex,
			item.ref as Hex,
			coordinate,
			entityData.id,
			angle,
		);
	},
} as TBluePrint;

registerEntity(entityData);
registerBlueprint(blueprint);
