import { CCrop } from "@game/sim/components/CCrop";
import { CEdible } from "@game/sim/components/CEdible";
import { CInventoryItem } from "@game/sim/components/CInventoryItem";
import { CPlantable } from "@game/sim/components/CPlantable";
import { CRenderer } from "@game/sim/components/CRenderer";
import { CStackable } from "@game/sim/components/CStackable";
import { CUpdate } from "@game/sim/components/CUpdate";
import type { SimEntity } from "@game/sim/SimEntity";
import { SimEntityPrefab } from "@game/sim/SimEntityPrefab";
import { ItemBerriesRenderer } from "./berries.renderer";
import { registerEntity } from "@/data/entity/entity.data.fn";
import type { TEntityDefinition } from "@/data/entity/entity.data.types";
import { registerFormula, type TFormula } from "@/data/formula/formula.data";

export class ItemBerriesPrefab extends SimEntityPrefab {
	crop: CCrop | null = null;

	constructor(e: SimEntity) {
		super(e);
		e.addComponent(CInventoryItem).setSigil("vegetable");
		e.addComponent(CRenderer).setRenderer(ItemBerriesRenderer);
		e.addComponent(CEdible);
		e.addComponent(CStackable).setMaxSize(30);
		e.addComponent(CPlantable).setProductPrefab(ItemBerriesPrefab);
		this.crop = e.addComponent(CCrop);
		e.addComponent(CUpdate).setUpdateFn(this.update.bind(this));
	}

	update = () => {
		this.crop?.checkGrowth();
	};
}

const entityData: TEntityDefinition = {
	name: "Berries",
	simEntityPrefab: ItemBerriesPrefab,
	id: 30013,
	metadata: {
		icon: "blackberries.webp",
	},
};

const blackberries = {
	name: "BlackberriesBlue",
	activeItem: [30003],
	combinesWith: [30013],
	produces: 30403,
	destroysActiveItem: false,
} as const as TFormula;

registerEntity(entityData);
registerFormula(blackberries);